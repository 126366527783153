<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "operation",
            title: "操作功能",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入操作功能"
              }
            }
          },
          {
            field: "businessId",
            title: "业务对象id",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入业务对象id"
              }
            }
          },
          {
            field: "brower",
            title: "客户端浏览器",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入客户端浏览器"
              }
            }
          },
          {
            field: "businessType",
            title: "操作模块",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入操作模块"
              }
            }
          },
          {
            field: "ipAddress",
            title: "IP地址",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入IP地址"
              }
            }
          },
          {
            field: "osName",
            title: "客户端操作系统",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入客户端操作系统"
              }
            }
          },
          {
            field: "params",
            title: "请求参数",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入请求参数"
              }
            }
          },
          {
            field: "requestUrl",
            title: "请求地址",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入请求地址"
              }
            }
          },
          {
            field: "results",
            title: "请求结果",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入请求结果"
              }
            }
          },
          {
            field: "createTime",
            title: "更新日期",
            minWidth: 160
          },
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {
          enabled: "1",
          operation: "",
          businessId: "",
          brower: "",
          businessType: "",
          ipAddress: "",
          osName: "",
          params: "",
          requestUrl: "",
          results: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [

        ],
        data: {},
      },
    };
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
